(function ($) {
  $(document).ready(function() {
    $('.navbar-toggle').click(function(){
      $(this).toggleClass('active');
    });

    function logoBGColor() {
      $('.navbar').toggleClass('color');
      if ($('body').hasClass('page-works')) {
        $(".navbar").toggleClass("red");
      } else if ($('body').hasClass('page-about')) {
        $(".navbar").toggleClass("green");
      } else if ($('body').hasClass('page-contact')) {
        $(".navbar").toggleClass("blue");
      } else {
        $(".navbar").toggleClass("red");
      }
    }

    function logoHoverIn() {
      $('.logo').removeClass('out').addClass('over');
      logoBGColor();
    }

    function logoHoverOut() {
      $('.logo').removeClass('over').addClass('out');
      logoBGColor();
    }

    $(".logo").hover(
        function () {
          logoHoverIn();
        },
        function () {
          logoHoverOut();
        }
    );

    $(".logo").bind("touchstart", function() {
      logoHoverIn();
    });

    $(".logo").bind("touchend", function() {
      logoHoverOut();
    });

    $('.work-hover').bind('touchstart touchend', function(e) {
        // e.preventDefault();
        $(this).toggleClass('hover_effect');
    });

    $(".navbar-nav li").hover(
      function () {
         $(".navbar").toggleClass("color");
      }
    );

    $(".navbar-nav li:nth-child(3n+1)").hover(
      function () {
        $(".navbar").toggleClass("red");
        $("#about-page .color-panel").toggleClass("grain-green").toggleClass("grain-red");
        $(".contact-page .color-panel").toggleClass("grain-blue").toggleClass("grain-red");
      }
    );

    $(".navbar-nav li:nth-child(3n+2)").hover(
      function () {
        $(".navbar").toggleClass("green");
        $(".works-new .top-row").toggleClass("grain-red").toggleClass("grain-green");
        $(".contact-page .color-panel").toggleClass("grain-blue").toggleClass("grain-green");
        $(".front-hero").toggleClass("green");

      }
    );

    $(".navbar-nav li:nth-child(3n+3)").hover(
      function () {
        $(".navbar").toggleClass("blue");
        $("#about-page .color-panel").toggleClass("grain-green").toggleClass("grain-blue");
        $(".works-new .top-row").toggleClass("grain-red").toggleClass("grain-blue");
        $(".front-hero").toggleClass("blue");
      }
    );
  });

  $(document).on('scroll', function(){
    var offset = $('.main-container').offset();

    if($('body').hasClass('front')) {
      if($(document).scrollTop()>=(offset.top - 68)) {

        if($('.navbar').hasClass('home-position')) {
          $('.navbar').removeClass('home-position');
        }
      }

      else {
        if(!$('.navbar').hasClass('home-position')) {
          $('.navbar').addClass('home-position');
        }
      }
    }
  });


  $('.popup-modal').magnificPopup({

      type: 'inline',
      modal: false,

  });

  $(document).on('click', '.closePopup', function (e)
    {
        e.preventDefault();
        $.magnificPopup.close();
    });

  $('#select-role').click(function(e) {
    	e.preventDefault();

      $('.view-content').toggleClass('show')
  });


  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $('header').outerHeight();

  $(window).scroll(function(event){
      didScroll = true;
  });

  setInterval(function() {
      if (didScroll) {
          hasScrolled();
          didScroll = false;
      }
  }, 250);

  function hasScrolled() {
      var st = $(this).scrollTop();

      // Make sure they scroll more than delta
      if(Math.abs(lastScrollTop - st) <= delta)
          return;

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (st > lastScrollTop && st > navbarHeight){
          // Scroll Down
          $('header').removeClass('nav-down').addClass('nav-up');
      } else {
          // Scroll Up
          if(st + $(window).height() < $(document).height()) {
              $('header').removeClass('nav-up').addClass('nav-down');
          }
      }

      lastScrollTop = st;
  }

  let text = `

 _     _ _______ ___     _______ _______ __   __ _______
| | _ | |       |   |   |       |       |  |_|  |       |
| || || |    ___|   |   |       |   _   |       |    ___|
|       |   |___|   |   |       |  | |  |       |   |___
|       |    ___|   |___|      _|  |_|  |       |    ___|
|   _   |   |___|       |     |_|       | ||_|| |   |___
|__| |__|_______|_______|_______|_______|_|   |_|_______|


Wondering what CMS or Framework I\'m using? Curious if I\'ve minified my site for production? Think you guessed how I did my less-than-subtle JS interactions on the page? Well, you\'re here in the inspector for some reason or another.

Relax, you've been caught, but clearly I\'m okay with it. In fact, I\'d love it if you now check out my new WIP site at designer.mattgastgeb.com. Drop me a line if you love it, hate it, or have a suggestion. I\'m mostly fishing for the suggestions.

And for the details you're looking for on this site:
CMS: Drupal 7
Theme: Bootstrap (with child theme)
Preprocessor: SCSS
Workflow: Gulp for SCSS preprocessig and JS linting. Gulp also to publish both.

Cheers.`;


  	console.log(text);
  })(jQuery);
